<template>
  <div>
    <navbar
      v-if="info.showNavbar"
      class="navbar"
      @toggle-drawer="showSidebar"
    />
    <sidebar @close="closeSidebar" v-if="info.showNavbar" />
    <div
      class="page_parent"
      style="position: relative;"
      :style="{...styles, top: info.showNavbar ? '' : '-73px'}"
    >
      <div
        class="page_sidebar vertical flex centered pl32"
        :class="{ active: sidebarOpen }"
      >
        <h3
          v-for="l in info.navbarLinks"
          :style="{ color: info.secondaryColor ? info.secondaryColor : '#333' }"
          class="my12 page_link capitalize pointer"
          @click="$bus.redirect(l.link, false, true)"
          :key="l.id"
        >
          {{ l.label }}
        </h3>
      </div>
      <div
        v-if="info.logo"
        @click="sidebarOpen = false"
        class="page_navbar"
        :style="{ background: info.primaryColor }"
      >
        <div
          class="myauto page_logo ml12"
          :style="{
            background: `url(${
              info.logo ? info.logo.url : ''
            }) center / contain no-repeat`
          }"
        ></div>
        <div
          v-if="$mq == 'desktop'"
          @mouseout="activeLink = ''"
          style="position: relative; left: -60px; height: 100%;"
          class="myauto links centered flex"
        >
          <span
            @mouseover="activeLink = l.id"
            v-for="l in info.navbarLinks"
            :key="l.id"
            class="myauto h24 relative"
          >
            <div
              class="page_link pointer bold mr20 capitalize"
              :style="{
                color: info.primaryColor ? 'white' : info.secondaryColor
              }"
              @click="$bus.redirect(l.link, false, true)"
            >
              {{ l.label }}
            </div>
            <div
              v-if="l.sublinks.length > 0"
              class="absolute sublinks"
              :class="{ active: activeLink == l.id }"
              @mouseover.stop="activeLink = l.id"
            >
              <div
                class="sublink capitalize pointer my12"
                :key="sl.id"
                @click="$bus.redirect(sl.link, false, true)"
                v-for="sl in l.sublinks"
              >
                {{ sl.label }}
              </div>
            </div>
          </span>
        </div>
        <button
          v-if="$mq != 'desktop'"
          class="navbar_button mlauto"
          @click.stop="sidebarOpen = !sidebarOpen"
        >
          <i
            class="bars icon"
            :style="{ color: info.primaryColor ? 'white' : '#333' }"
          ></i>
        </button>
      </div>

      <div
        @click="sidebarOpen = false"
        v-for="s in info.sections"
        :key="s.id"
        :style="processStyles(s.styles)"
      >
        <div v-if="s.type == 'html'" v-html="compileHTML(s.html, s.css)"></div>
        <campaign
          v-if="active && active.id && s.type == 'registration'"
          :campaign="active"
          isPage="true"
        />
        <div
          v-if="s.type == 'text'"
          :class="[$mq == 'mobile' ? 'px12' : 'px40']"
          v-html="s.text"
        ></div>
        <div
          v-if="s.type == 'textNimage'"
          :class="[$mq == 'mobile' ? 'p0' : 'p40']"
          class="textNimage"
        >
          <img v-if="s.images[0]" style="display: block" height="100%" :alt="s.images[0].alternativeText" :src="s.images[0].url" />
          <div v-html="s.text" class="mauto px20 text"></div>
        </div>
        <div v-if="s.type == 'files'">
          <div v-if="s.files && s.files.length > 0">
            <h6 class="fs16 mt32 mb12">Attachments</h6>
            <a
              class="files pointer mt4"
              v-for="f in s.files"
              @click="$bus.redirect(f.url, false, true)"
              :key="f.name"
              >{{ f.name }}</a
            >
          </div>
        </div>
        <!-- img v-if="$mq == 'desktop' && s.images && s.images[0] && s.type == 'image'" 
          class="mxauto"
          style="display: block; height: 70vh; text-align: center;" 
          :src="s.images[0].url" -->
        <img
          v-if="s.images && s.images[0] && s.type == 'image'"
          style="width: 100%;"
          :src="s.images[0].url"
          :alt="s.images[0].alternativeText"
        />
      </div>
      <div class="page_footer">
        <div class="links">
          <span v-for="l in info.navbarLinks" :key="l.id" class="mr24">
            <div
              class="page_link pointer bold capitalize fs16"
              :style="{ color: info.primaryColor ? 'white' : '' }"
              @click="$bus.redirect(l.link, false, true)"
            >
              {{ l.label }}
            </div>
            <div class="mt12">
              <p
                v-for="sl in l.sublinks"
                :key="sl.id"
                class="capitalize sublink pointer my8"
                style="font-weight: 300; font-size: 14px"
              >
                {{ sl.label }}
              </p>
            </div>
          </span>
        </div>
      </div>
    </div>
    <!-- div>
      <div>
        <div class="page_navbar">
          <div
            class="myauto page_logo ml12"
            :style="{background: `url(${info.logo.url}) center / contain no-repeat`}"></div>
            <div class="myauto links evenly spaced flex">
              <div 
                v-for="l in info.links"
                class="pointer capitalize"
                @click="$bus.redirect(l.link, false, true)">
                {{l.label}}
              </div>
            </div>
          </div>
        </div>
        <div>
          {{info}}
        </div>
      </div>
    </div -->
  </div>
</template>

<script>
import navbar from '../components/navbar'
import sidebar from '../components/sidebar'
import campaign from "../components/campaign";
export default {
  name: "page",
  components: { campaign, navbar, sidebar },
  metaInfo () {
    return {
      title: this.info?.SEO?.metaTitle || "",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: this.info?.SEO?.metaDescription || ""
        },
        {
          hid: "keywords",
          name: "keywords",
          content: this.info?.SEO?.metaKeywords || ""
        }
      ]
    }
  },
  data() {
    return {
      key: this.ID(),
      info: {},
      sidebarOpen: false,
      activeLink: "",
      active: false,
      links: [
        {
          name: "home",
          link: "http://google.com"
        }
      ]
    };
  },
  watch: {
    $route() {
      let name = this.$route.params.name;
      this.getPageData(name);
      window.scrollTo(0, 0);
    }
  },
  mounted() {
    let name = this.$route.params.name;
    this.getPageData(name);
  },
  computed: {
    styles() {
      if (!this.info) {
        return {};
      }
      return {
        "--link-color": this.info.secondaryColor
          ? this.info.secondaryColor
          : "auto",
        "--button-color": this.info.secondaryColor
          ? this.info.secondaryColor
          : "#333",
        "--button-text-color": "white",
        "--footer-background": this.info.primaryColor
          ? this.info.primaryColor
          : "#333",
        "--paragraph-size": "16px",
        "--l-paragraph-size": "20px",
        // "--header-size": "24px",
        // "--l-header-size": "32px"
      };
    }
  },
  methods: {
    processStyles: function(styleArray) {
      let obj = {};
      if (!styleArray) {
        return obj;
      }
      for (let el of styleArray) {
        obj[el.property] = el.value;
      }
      return obj;
    },
    ID: function() {
      // Generates a random Id
      return (
        "_" +
        Math.random()
          .toString(36)
          .substr(2, 9)
      );
    },
    compileHTML: function (html, css) {
      if (css) {
        return html + '<style> :root { --dl-space-space-twounits: 32px; --dl-space-space-unit: 16px; }' + css + '</style>'  
      }
      return html
    },
    getPageData: function(name) {
      this.$req
        .get(`pages?link=${name}`)
        .then(r => {
          this.info = r.data[0];
          this.$req
            .get(`events?id=${this.info?.event?.id}`)
            .then(r => {
              this.active = r.data[0];
            })
            .catch(e => console.log(e));
        })
        .catch(e => console.log(e));
    }
  }
};
</script>

<style lang="scss">
.page_parent h1 { font-size: 32px; }
.page_parent h2 { font-size: 28px; }
.page_parent h3 { font-size: 24px; }
.page_parent h4 { font-size: 20px; }

.page_navbar {
  width: 100%;
  height: 72px;
  background: white;
  display: flex;
}

.page_link {
  transition: 0.2s;
}

.page_logo {
  width: 90px;
  height: 90px;
}

.links {
  display: flex;
  flex-grow: 1;
}

.page_sidebar {
  position: fixed;
  right: -250px;
  top: 0px;
  width: 250px;
  height: 100%;
  background: white;
  transition: 0.3s;
  border-left: 1px solid #eee;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.page_sidebar.active {
  right: 0px;
}
.textNimage {
  display: flex;
  flex-wrap: wrap;
}
.textNimage img {
  width: 50%;
  margin: auto;
}
.textNimage .text {
  width: 50%;
  padding-left: 40px;
}

@media (max-width: 1400px) {
  .textNimage img {
    width: 45%;
  }
  .textNimage .text {
    flex-grow: 1;
  }
}

@media (max-width: 900px) {
  .textNimage img {
    width: 100%;
  }
  .textNimage .text {
    width: 100%;
    padding: 40px 12px;
  }
}

.page_link:hover,
.sublink:hover {
  opacity: 0.6;
}
.page_link:active,
.sublink:active {
  opacity: 0.8;
}

.page_parent a {
  color: var(--link-color) !important;
  &:hover {
    opacity: 0.6;
  }
  &:active {
    opacity: 0.8;
  }
}

.page_parent button {
  background: var(--button-color);
  color: var(--button-text-color);
  &:hover {
    background: var(--button-color);
    opacity: 0.7;
  }
  &:active {
    background: var(--button-color);
    opacity: 0.85;
  }
}

.navbar_button {
  background: transparent !important;
  i {
    font-size: 20px;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.1) !important;
  }
  &:active {
    background: rgba(0, 0, 0, 0.2) !important;
  }
}

.page_footer {
  background: var(--footer-background);
  color: white;
  padding: 40px;
}

.sublinks {
  padding: 12px 20px;
  border: 1px solid #eee;
  position: absolute;
  top: 30px;
  background: white;
  height: 0px;
  overflow: hidden;
  opacity: 0;
  border-radius: 4px;
}

.sublinks.active {
  height: fit-content;
  overflow: visible;
  opacity: 1;
}

.page_parent ul {
  margin-top: 0px;
}
/* .page_parent p {
  font-size: var(--paragraph-size);
}
.page_parent h1,
.page_parent h2 {
  font-size: var(--header-size);
}
.page_parent li {
  font-size: var(--paragraph-size);
} */
</style>
